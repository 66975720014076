export var scriptRegex = /(<|&#x3C;)script(.*?)(>|&#x3E;)(.*?)(<|&#x3C;)\/script(>|&#x3E;)/g;
export var extractMediaScripts = function(page) {
    var blocks = page === null || page === void 0 ? void 0 : page.blocks;
    if (!blocks) {
        return [];
    }
    var scripts = blocks.filter(function(param) {
        var __typename = param.__typename;
        return __typename === "MediaComponent";
    }).map(function(block) {
        var _a;
        var content = block.content;
        var ref = content || {}, url = ref.url, script = ref.script;
        if (url) {
            return;
        }
        var match = script && ((_a = script.match(scriptRegex)) === null || _a === void 0 ? void 0 : _a.toString());
        if (!match) {
            return;
        }
        return replaceQuotes(match);
    }).filter(function(script) {
        return script;
    });
    return scripts;
};
export var removeMediaScripts = function(text) {
    return replaceQuotes(text).replace(scriptRegex, "");
};
export var replaceQuotes = function(text) {
    return text.replace(/“|”/g, '"');
};
